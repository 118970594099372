/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/
* {
  box-sizing: border-box; }

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  vertical-align: baseline; }

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block; }

body {
  line-height: 1; }

ol, ul {
  list-style: none; }

blockquote, q {
  quotes: none; }

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

@font-face {
  font-family: 'Ubuntu';
  src: url("./fonts/Ubuntu-Medium.woff2") format("woff2"), url("./fonts/Ubuntu-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal; }
@font-face {
  font-family: 'Open Sans';
  src: url("./fonts/OpenSans-Bold.woff2") format("woff2"), url("./fonts/OpenSans-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal; }
@font-face {
  font-family: 'Open Sans';
  src: url("./fonts/OpenSans-Regular.woff2") format("woff2"), url("./fonts/OpenSans-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal; }
h1,
.h1,
h2,
.h2,
h3,
.h3 {
  font-family: "Ubuntu", "Helvetica", "Arial", sans-serif;
  font-weight: 500;
  margin-bottom: 1rem;
  line-height: 1.5; }

h2, .h2 {
  font-size: 1.35rem; }
  @media (min-width: 768px) {
    h2, .h2 {
      font-size: 1.5rem; } }

p {
  line-height: 1.5; }

.key-value {
  position: relative;
  margin-bottom: 1rem; }
  .key-value p {
    line-height: 1.2; }

body {
  font-family: "Open Sans", "Helvetica", "Arial", sans-serif;
  font-size: 18px;
  color: #2c2c2c;
  background-color: #f6f6f6; }

.brand-logo {
  position: relative;
  height: 50px;
  text-align: center;
  margin-bottom: 3rem; }
  .brand-logo img {
    position: relative;
    height: 100%;
    width: auto; }

hr {
  border: 0;
  height: 1px;
  background: #f6f6f6; }

.main-content {
  position: relative;
  padding: 96px 0 0 0; }
  @media (min-width: 992px) {
    .main-content {
      padding: 96px 0 0 216px; } }
  .main-content__inner {
    position: relative;
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 1rem; }

@media (min-width: 768px) {
  .layout-grid {
    display: grid;
    grid-template-columns: repeat(8, 1fr);
    grid-gap: 1rem;
    margin-bottom: 1rem; } }

.grid {
  display: grid; }
  .grid--2-col {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 1rem; }

.f-container {
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap; }

.f-item {
  -ms-flex: 1 0 50%;
      flex: 1 0 50%; }

.general-content {
  position: relative;
  width: 100%;
  background-color: #f6f6f6;
  height: 100%;
  min-height: 100vh;
  padding: 2rem 1rem;
  transition: all .3s ease-in-out; }
  .general-content__inner {
    position: relative;
    width: 100%;
    max-width: 1000px;
    margin: 0 auto;
    background-color: white;
    border-radius: 16px;
    transform: translateY(100vh);
    opacity: 0;
    padding: 1rem;
    transition: all .3s ease-in-out; }
    @media (min-width: 768px) {
      .general-content__inner {
        padding: 1.5rem; } }
    .animate-in .general-content__inner {
      transform: translateY(0px);
      opacity: 1;
      box-shadow: 0px 0px 20px 4px rgba(44, 44, 44, 0.05); }
    .animate-out .general-content__inner {
      transform: translateY(-100%);
      opacity: 0;
      box-shadow: none;
      transition: all .3s ease-in-out .5s; }

.split-panel {
  position: relative;
  width: 100%;
  height: 100%;
  min-height: 100vh;
  transition: all .3s ease-in-out .6s; }
  @media (min-width: 992px) {
    .split-panel {
      display: -ms-flexbox;
      display: flex; } }
  .split-panel__section {
    position: relative;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    height: 100%;
    min-height: 100vh;
    padding: 1rem;
    -ms-flex-align: center;
        align-items: center;
    transition: all .3s ease-in-out .3s; }
    .split-panel__section:first-of-type {
      background-color: #4260f4;
      color: white; }
    .split-panel__section:last-of-type {
      background-color: #4260f4;
      color: white; }
    @media (min-width: 768px) {
      .split-panel__section {
        -ms-flex-pack: center;
            justify-content: center; } }
    @media (min-width: 992px) {
      .split-panel__section {
        -ms-flex: 1 0 50%;
            flex: 1 0 50%; }
        .split-panel__section:last-of-type {
          background-color: white;
          color: #2c2c2c; }
          .split-panel__section:last-of-type label {
            color: #2c2c2c; } }
  .split-panel__inner {
    position: relative;
    width: 100%;
    max-width: 480px; }
  .split-panel__prev {
    position: absolute;
    top: 2rem;
    width: 100%;
    max-width: 480px;
    margin: 0 auto;
    transition: all .3s ease-in-out .3s; }
  .split-panel.transition-out {
    overflow-x: hidden; }
    .split-panel.transition-out .split-panel__section:first-of-type {
      -ms-flex: 1 0 100%;
          flex: 1 0 100%;
      background-color: #f6f6f6; }
    .split-panel.transition-out .split-panel__section:last-of-type {
      -ms-flex: 0;
          flex: 0;
      padding: 0; }
    .split-panel.transition-out .split-panel__inner {
      opacity: 0; }
    .split-panel.transition-out .split-panel__prev {
      opacity: 0; }

button {
  font-family: "Open Sans", "Helvetica", "Arial", sans-serif; }

.btn {
  position: relative;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-pack: center;
      justify-content: center;
  border: none;
  background-color: #4260f4;
  color: white;
  height: 50px;
  padding: 0 1rem;
  border-radius: 4px;
  font-size: .8rem;
  font-weight: bold;
  font-family: "Open Sans", "Helvetica", "Arial", sans-serif;
  text-transform: uppercase;
  text-decoration: none;
  transition: all .3s ease-in-out; }
  .btn:hover {
    background-color: #2a4cf3; }
  .btn[disabled] {
    opacity: 0.5;
    cursor: not-allowed; }
  p + .btn {
    margin-top: 1.5rem; }
  .btn--white {
    background-color: rgba(255, 255, 255, 0.9);
    color: #2c2c2c; }
    .btn--white:hover {
      background-color: white; }
  .btn--icon {
    padding-right: calc(50px + 1rem); }
    .btn--icon span {
      position: absolute;
      display: -ms-flexbox;
      display: flex;
      -ms-flex-pack: center;
          justify-content: center;
      -ms-flex-align: center;
          align-items: center;
      right: 0;
      top: 0;
      width: 50px;
      height: 50px;
      border-radius: 4px;
      background-color: #2a4cf3; }
      .btn--icon span svg {
        width: 20px;
        height: 20px;
        fill: white; }
    .btn--icon.btn--white span {
      background-color: white; }
      .btn--icon.btn--white span svg {
        fill: #2c2c2c; }

.icon-btn {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: center;
      align-items: center;
  width: 40px;
  height: 40px;
  border-radius: 4px;
  background-color: #f6f6f6;
  border: none;
  transition: all .3s ease-in-out; }
  .icon-btn svg {
    width: 20px;
    height: 20px;
    fill: #2c2c2c; }
  .icon-btn[disabled] {
    opacity: .5;
    cursor: not-allowed; }
  .icon-btn--white {
    background-color: white; }
  .split-panel__prev .icon-btn {
    background-color: rgba(255, 255, 255, 0.5); }
    .split-panel__prev .icon-btn:hover {
      background-color: white; }

.icon-only-btn {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: center;
      align-items: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #f6f6f6;
  border: none;
  transition: all .3s ease-in-out; }
  .icon-only-btn svg {
    width: 26px;
    height: 26px;
    fill: #2c2c2c; }
  .icon-only-btn:hover {
    background-color: #dddddd; }
  .icon-only-btn--small {
    width: 30px;
    height: 30px; }
    .icon-only-btn--small svg {
      width: 16px;
      height: 16px; }

.pill-btn {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  padding: 0 1rem;
  height: 40px;
  background-color: #dddddd;
  border: none;
  color: #2c2c2c;
  font-family: "Open Sans", "Helvetica", "Arial", sans-serif;
  font-weight: bold;
  text-transform: uppercase;
  text-decoration: none;
  letter-spacing: 1px;
  font-size: .85rem;
  -ms-flex: none;
      flex: none;
  margin-right: 1rem;
  border-radius: 4px;
  transition: all .3s ease-in-out; }
  .pill-btn:hover {
    color: white;
    background-color: #2a4cf3; }
  .pill-btn.is-active {
    background-color: #4260f4;
    color: white; }
  @media (min-width: 768px) {
    .pill-btn {
      background-color: transparent; }
      .pill-btn:hover {
        background-color: #dddddd;
        color: #2c2c2c; } }

.nav-trigger {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: center;
      align-items: center;
  background-color: transparent;
  border: none;
  width: 60px;
  height: 60px; }
  .nav-trigger svg {
    fill: #2c2c2c;
    width: 40px;
    height: 40px; }

.payment-method-form {
  width: 100%;
  padding: 1rem; }

.form-group {
  position: relative;
  margin-bottom: 1rem; }
  @media (min-width: 768px) {
    .form-group {
      margin-bottom: 0; } }

label {
  display: block;
  font-size: 18px;
  color: white; }
  label + input, label + select, label + textarea {
    margin-top: 1rem; }
  .split-panel__inner label {
    font-weight: bold;
    margin-top: 1rem; }

input[type=email],
input[type=text],
input[type=password],
input[type=number],
input[type=date],
select,
textarea {
  position: relative;
  display: block;
  border: 1px solid #f6f6f6;
  border-radius: 4px;
  width: 100%;
  height: 50px;
  background-color: rgba(255, 255, 255, 0.9);
  padding: 0 1rem;
  font-size: 18px; }
  input[type=email] + label,
  input[type=text] + label,
  input[type=password] + label,
  input[type=number] + label,
  input[type=date] + label,
  select + label,
  textarea + label {
    margin-top: 1.5rem; }
  input[type=email] + button,
  input[type=text] + button,
  input[type=password] + button,
  input[type=number] + button,
  input[type=date] + button,
  select + button,
  textarea + button {
    margin-top: 1rem; }

.radio-style {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: center;
      align-items: center;
  width: 100%;
  height: 50px;
  border: 2px solid #f6f6f6;
  border-radius: 8px;
  transition: all .3s ease-in-out; }
  .radio-style label {
    margin: 0;
    transition: all .3s ease-in-out; }
  .radio-style input[type=radio] {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0; }
  .radio-style.is-selected {
    border-color: #4260f4;
    background-color: #4260f4; }
    .radio-style.is-selected label {
      font-weight: bold;
      color: white; }

.search-filter {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  -ms-flex: none;
      flex: none;
  -ms-flex-align: center;
      align-items: center;
  margin-top: .5rem; }
  .search-filter input[type=text] {
    -ms-flex: 1;
        flex: 1;
    margin-right: .75rem; }
  .search-filter .filter-dropdown {
    -ms-flex: none;
        flex: none; }
  @media (min-width: 768px) {
    .search-filter {
      width: auto;
      margin-left: auto;
      margin-top: 0; }
      .search-filter input[type=text] {
        max-width: 200px; } }

.thumbnail {
  width: 40px;
  height: 40px;
  border-radius: 4px;
  overflow: hidden;
  border: 1px solid #f6f6f6; }
  .thumbnail img {
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
       object-fit: cover;
    -o-object-position: center;
       object-position: center; }

.w100-hauto {
  position: relative;
  width: 100%;
  height: auto; }

.cover-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; }
  .cover-image img {
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
       object-fit: cover;
    -o-object-position: center;
       object-position: center; }

.loader-container {
  position: fixed;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: center;
      align-items: center;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(44, 44, 44, 0.45);
  z-index: 1000;
  visibility: hidden;
  opacity: 0;
  transition: all .3s ease-in-out; }
  .loader-container.is-active {
    visibility: visible;
    opacity: 1; }

.loader,
.loader:before,
.loader:after {
  border-radius: 50%;
  width: 2.5em;
  height: 2.5em;
  animation-fill-mode: both;
  animation: load7 1.8s infinite ease-in-out; }

.loader {
  color: #4260f4;
  font-size: 10px;
  margin: 80px auto;
  position: relative;
  text-indent: -9999em;
  transform: translateZ(0);
  animation-delay: -0.16s; }

.loader:before,
.loader:after {
  content: '';
  position: absolute;
  top: 0; }

.loader:before {
  left: -3.5em;
  animation-delay: -0.32s; }

.loader:after {
  left: 3.5em; }
@keyframes load7 {
  0%,
    80%,
    100% {
    box-shadow: 0 2.5em 0 -1.3em; }
  40% {
    box-shadow: 0 2.5em 0 0; } }
.image-loader {
  position: absolute;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-pack: center;
      justify-content: center;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10; }

.image-loader:after {
  content: " ";
  display: block;
  width: 34px;
  height: 34px;
  margin: 8px;
  border-radius: 50%;
  border: 6px solid #fff;
  border-color: #fff transparent #fff transparent;
  animation: lds-dual-ring 1.2s linear infinite; }

@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }
.info-reveal {
  position: relative;
  width: 100%;
  border-radius: 8px;
  background-color: #f6f6f6;
  padding: 1rem;
  height: 0px;
  opacity: 0;
  transition: all .3s ease-in-out; }
  .info-reveal * {
    opacity: 0;
    transition: all .3s ease-in-out .3s; }
  .info-reveal.is-open {
    height: auto;
    opacity: 1; }
    .info-reveal.is-open * {
      opacity: 1; }

.card {
  background-color: white;
  border-radius: 12px;
  min-height: 300px;
  margin-bottom: 1rem;
  box-shadow: 0 7px 14px 0 rgba(60, 66, 87, 0.12), 0 3px 6px 0 rgba(167, 167, 167, 0.12); }
  @media (min-width: 768px) {
    .card {
      margin-bottom: 0;
      border-radius: 4px; } }

.nutrition-card {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  padding: 1rem;
  grid-column: auto / span 4;
  -ms-flex-direction: column;
      flex-direction: column;
  background: linear-gradient(to right, #56ab2f, #a8e063);
  /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  color: white; }
  .nutrition-card__icon {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
        align-items: center;
    -ms-flex: none;
        flex: none;
    justify-self: flex-start;
    padding-bottom: 1rem; }
  .nutrition-card__title {
    font-weight: bold;
    font-size: .9rem;
    letter-spacing: 1px;
    text-transform: uppercase; }
  .nutrition-card__content {
    position: relative;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
        flex-direction: column;
    -ms-flex: 1;
        flex: 1; }
  .nutrition-card__stats {
    -ms-flex: none;
        flex: none;
    display: grid;
    border-radius: 4px;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 1rem;
    margin-top: auto; }

.nutrition-target {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  padding: 1rem;
  grid-column: auto / span 8;
  -ms-flex-direction: column;
      flex-direction: column;
  min-height: 0; }
  .nutrition-target__icon {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
        align-items: center;
    -ms-flex: none;
        flex: none;
    justify-self: flex-start;
    padding-bottom: 1rem; }
  .nutrition-target__title {
    font-weight: bold;
    font-size: .9rem;
    letter-spacing: 1px;
    text-transform: uppercase; }
  .nutrition-target__content {
    position: relative;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
        flex-direction: column;
    -ms-flex: 1;
        flex: 1; }
  .nutrition-target__stats {
    -ms-flex: none;
        flex: none;
    display: grid;
    border-radius: 4px;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 1rem;
    margin-top: auto; }
    @media (min-width: 768px) {
      .nutrition-target__stats {
        grid-template-columns: repeat(4, 1fr); } }

.calories-stat {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-pack: center;
      justify-content: center;
  text-align: center;
  -ms-flex: 1 0 calc(50% - 1rem);
      flex: 1 0 calc(50% - 1rem);
  margin-bottom: 2rem;
  font-weight: bold; }
  .calories-stat__label {
    font-size: 0.9rem;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-weight: bold;
    color: rgba(255, 255, 255, 0.65); }
  .calories-stat__primary {
    font-size: 1.8rem; }
    @media (min-width: 768px) {
      .calories-stat__primary {
        font-size: 2rem; } }

.nutrition-stat {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  padding: 1rem;
  font-weight: bold;
  border-radius: 4px;
  color: rgba(44, 44, 44, 0.75);
  -ms-flex: 1;
      flex: 1;
  background-color: #fffffff2;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-direction: column;
      flex-direction: column;
  box-shadow: 0 7px 14px 0 rgba(60, 66, 87, 0.12), 0 3px 6px 0 rgba(167, 167, 167, 0.12); }
  .nutrition-stat__primary {
    font-size: 1.5rem; }
  .nutrition-stat__unit {
    opacity: .8; }
  .nutrition-target .nutrition-stat {
    box-shadow: none;
    border: 2px solid #dddddd; }

.profile-card {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  padding: 1rem;
  grid-column: auto / span 3;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: center;
      align-items: center; }
  .profile-card__content {
    position: relative;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
        flex-direction: column;
    -ms-flex-align: center;
        align-items: center;
    width: 100%;
    max-width: 340px; }

.quick-action {
  display: -ms-flexbox;
  display: flex;
  min-height: auto; }
  @media (min-width: 768px) {
    .quick-action {
      -ms-flex-direction: column;
          flex-direction: column;
      min-height: 300px; }
      .quick-action .sidebar-item {
        -ms-flex: 1;
            flex: 1; } }

.next-workout {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  grid-column: auto / span 4;
  background-color: #4260f4;
  background: linear-gradient(to right, #f2994a, #f2c94c);
  /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  padding: 1rem; }
  .next-workout__icon {
    -ms-flex: 1;
        flex: 1;
    justify-self: flex-start; }
    .next-workout__icon .sidebar-item__icon {
      background-color: rgba(255, 255, 255, 0.85); }
  .next-workout__content {
    position: relative;
    justify-self: flex-end; }
  .next-workout__subtitle {
    font-size: 0.9rem;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-weight: bold;
    color: rgba(255, 255, 255, 0.65); }
  .next-workout__title {
    font-family: "Ubuntu", "Helvetica", "Arial", sans-serif;
    color: white;
    font-size: 1.5rem; }

.next-checkin {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  grid-column: auto / span 3;
  background-color: #4260f4;
  background: linear-gradient(to right, #f2994a, #f2c94c);
  /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  padding: 1rem; }
  .next-checkin__icon {
    -ms-flex: 1;
        flex: 1;
    justify-self: flex-start; }
    .next-checkin__icon .sidebar-item__icon {
      background-color: rgba(255, 255, 255, 0.85); }
  .next-checkin__content {
    position: relative;
    justify-self: flex-end; }
  .next-checkin__subtitle {
    font-size: 0.9rem;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-weight: bold;
    color: rgba(255, 255, 255, 0.65); }
  .next-checkin__title {
    font-family: "Ubuntu", "Helvetica", "Arial", sans-serif;
    color: white;
    font-size: 1.5rem; }

.statistic-card {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  grid-column: auto / span 8;
  overflow: hidden; }
  .statistic-card__item {
    position: relative;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
        flex-direction: column;
    width: 50%;
    height: 120px;
    padding: 1rem;
    transition: all .3s ease-in-out;
    cursor: pointer; }
    .statistic-card__item.is-active {
      background-color: #4260f4;
      color: white; }
      .statistic-card__item.is-active:after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 50%;
        background-color: rgba(255, 255, 255, 0.05);
        pointer-events: none; }
    @media screen and (max-width: 767px) {
      .statistic-card__item:nth-of-type(n + 3) {
        border-top: 1px solid #dddddd; }
      .statistic-card__item:not(:nth-of-type(2n + 2)) {
        border-right: 1px solid #dddddd; } }
    @media (min-width: 768px) {
      .statistic-card__item {
        width: 25%;
        height: 200px; }
        .statistic-card__item:nth-of-type(n + 5) {
          border-top: 1px solid #dddddd; }
        .statistic-card__item:not(:nth-of-type(4n)) {
          border-right: 1px solid #dddddd; } }
  .statistic-card__title {
    display: -ms-inline-flexbox;
    display: inline-flex;
    -ms-flex-align: center;
        align-items: center;
    -ms-flex: none;
        flex: none;
    border: none;
    border-radius: 4px;
    font-weight: bold;
    font-size: .9rem;
    letter-spacing: 1px;
    text-transform: uppercase;
    -ms-flex-item-align: start;
        align-self: flex-start; }
    @media (min-width: 768px) {
      .statistic-card__title {
        position: absolute;
        height: 40px;
        background-color: #2c2c2c;
        color: white;
        padding: 0 1rem;
        transition: all .3s ease-in-out; }
        .is-active .statistic-card__title {
          background-color: rgba(255, 255, 255, 0.15); } }
  .statistic-card__stat {
    display: -ms-flexbox;
    display: flex;
    -ms-flex: 1;
        flex: 1;
    -ms-flex-align: center;
        align-items: center;
    -ms-flex-pack: center;
        justify-content: center; }
    .statistic-card__stat p {
      font-size: 1.6rem;
      font-weight: 500; }
      @media (min-width: 768px) {
        .statistic-card__stat p {
          font-size: 2.4rem; } }
    .statistic-card__stat span {
      font-size: 1.2rem;
      font-weight: bold; }

.stat-card-container {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex: 1 0 50%;
      flex: 1 0 50%; }

.stat-card {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex: none;
      flex: none;
  -ms-flex-direction: column;
      flex-direction: column;
  width: 100%;
  height: 100%;
  background-color: white;
  border-radius: 16px;
  padding: 1.5rem;
  margin-bottom: 1rem;
  border: 1px solid #f6f6f6; }
  @media (min-width: 768px) {
    .stat-card {
      -ms-flex: 1 0 calc(50% - .5rem);
          flex: 1 0 calc(50% - .5rem); }
      .stat-card:nth-child(2n + 1) {
        margin-right: .5rem; }
      .stat-card:nth-child(2n + 2) {
        margin-left: .5rem; } }
  .stat-card__icon {
    position: absolute;
    top: 1.5rem;
    right: 1.5rem; }
    .stat-card__icon svg {
      width: 24px;
      height: 24px;
      fill: #2c2c2c; }
  .stat-card__info {
    margin-top: auto; }
  .stat-card__figure {
    font-size: 2rem;
    line-height: 1; }
  .stat-card--blue {
    background: linear-gradient(180deg, #42a9f4 0%, #4260f4 100%);
    color: white; }
    .stat-card--blue svg {
      fill: white; }

.note-box {
  min-height: 0;
  margin-bottom: 1rem; }
  .note-box__header {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
        align-items: center;
    width: 100%;
    background-color: transparent;
    border: none;
    overflow: hidden;
    padding: 0;
    border-radius: 4px; }
  .note-box__title {
    font-weight: bold;
    font-size: 1rem;
    padding: 1rem; }
  .note-box__indicator {
    margin-left: auto;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
        align-items: center;
    -ms-flex-pack: center;
        justify-content: center;
    width: 100px;
    height: 100%;
    background-color: #4260f4;
    color: white;
    font-size: 1.3rem;
    padding: 1rem;
    width: 60px; }
  .note-box__main {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
        flex-direction: column;
    max-height: 0;
    height: 0;
    visibility: hidden;
    transition: all .3s ease-in-out; }
    .note-box.is-open .note-box__main {
      max-height: 1000px;
      height: 280px;
      visibility: visible; }
    .note-box__main textarea {
      -ms-flex: 1;
          flex: 1;
      padding: 1rem; }

.personal-details-card {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  grid-column: auto / span 5;
  padding: 1rem; }
  .personal-details-card__icon {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
        align-items: center;
    -ms-flex: none;
        flex: none;
    justify-self: flex-start;
    padding-bottom: 1rem; }
  .personal-details-card__title {
    font-weight: bold;
    font-size: .9rem;
    letter-spacing: 1px;
    text-transform: uppercase; }
  .personal-details-card__content {
    position: relative;
    display: -ms-flexbox;
    display: flex; }
    .personal-details-card__content label {
      color: #2c2c2c; }
    .personal-details-card__content input {
      background-color: rgba(195, 195, 195, 0.4); }
    .personal-details-card__content form {
      width: 100%; }
    @media (min-width: 768px) {
      .personal-details-card__content form {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 1rem; } }

.reset-password-card {
  grid-column: auto / span 8;
  min-height: 0;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  padding: 1rem; }
  .reset-password-card__icon {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
        align-items: center;
    -ms-flex: none;
        flex: none;
    justify-self: flex-start;
    padding-bottom: 1rem; }
  .reset-password-card__title {
    font-weight: bold;
    font-size: .9rem;
    letter-spacing: 1px;
    text-transform: uppercase; }
  .reset-password-card__content {
    position: relative;
    display: -ms-flexbox;
    display: flex; }
    .reset-password-card__content label {
      color: #2c2c2c; }
    .reset-password-card__content input {
      background-color: rgba(195, 195, 195, 0.4); }
    .reset-password-card__content form {
      width: 100%; }
    @media (min-width: 768px) {
      .reset-password-card__content form {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-gap: 1rem; } }

.payment-history {
  grid-column: auto / span 8;
  min-height: 0;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column; }
  .payment-history__icon {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
        align-items: center;
    -ms-flex: none;
        flex: none;
    justify-self: flex-start;
    padding: 1rem; }
  .payment-history__title {
    font-weight: bold;
    font-size: .9rem;
    letter-spacing: 1px;
    text-transform: uppercase; }
  .payment-history__content {
    position: relative;
    display: -ms-flexbox;
    display: flex; }

.workout-stat-card {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  grid-column: auto / span 4; }
  .workout-stat-card__icon {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
        align-items: center;
    -ms-flex: none;
        flex: none;
    justify-self: flex-start;
    padding: 1rem;
    border-bottom: 1px solid #dddddd; }
    .workout-stat-card__icon .sidebar-item__icon {
      background-color: #4260f4; }
      .workout-stat-card__icon .sidebar-item__icon svg {
        fill: white; }
  .workout-stat-card__title {
    color: rgba(44, 44, 44, 0.65);
    font-weight: bold;
    font-size: .9rem;
    letter-spacing: 1px;
    text-transform: uppercase; }
  .workout-stat-card__content {
    -ms-flex: 1;
        flex: 1;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: 50%; }

.stat-item {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center; }
  .stat-item:nth-of-type(2n) {
    border-left: 1px solid #dddddd; }
  .stat-item:nth-of-type(1n + 3) {
    border-top: 1px solid #dddddd; }
  .stat-item__icon {
    position: absolute;
    top: 0;
    right: 0;
    -ms-flex: none;
        flex: none;
    display: -ms-flexbox;
    display: flex;
    width: 40px;
    height: 40px;
    -ms-flex-pack: center;
        justify-content: center;
    -ms-flex-align: center;
        align-items: center;
    -ms-flex-item-align: start;
        align-self: flex-start;
    margin-right: .5rem;
    margin-top: .5rem; }
    .stat-item__icon svg {
      width: 24px;
      height: 24px;
      fill: #4260f4;
      opacity: .65;
      transition: all .3s ease-in-out; }
      .stat-item:hover .stat-item__icon svg {
        opacity: 1;
        transform: scale(1.1) rotate(10deg); }
  .stat-item__content {
    -ms-flex: 1;
        flex: 1;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
        flex-direction: column;
    padding: 1rem;
    -ms-flex-align: center;
        align-items: center;
    -ms-flex-pack: center;
        justify-content: center; }
  .stat-item__value {
    font-size: 2rem; }
    .stat-item__value span {
      font-size: 1rem;
      opacity: .65; }
  .stat-item__key {
    font-weight: bold;
    font-size: .85rem;
    text-transform: uppercase;
    margin-top: .5rem;
    opacity: .65;
    line-height: 1.5; }

.on-track-card {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  grid-column: auto / span 8;
  -ms-flex-direction: column;
      flex-direction: column;
  overflow: hidden; }
  .on-track-card__icon {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
        align-items: center;
    -ms-flex: none;
        flex: none;
    justify-self: flex-start;
    padding-bottom: 1rem; }
  .on-track-card__title {
    font-weight: bold;
    font-size: .9rem;
    letter-spacing: 1px;
    text-transform: uppercase; }
  .on-track-card__main {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
        flex-direction: column;
    -ms-flex: 1;
        flex: 1; }
    @media (min-width: 768px) {
      .on-track-card__main {
        -ms-flex-direction: row;
            flex-direction: row; } }
  .on-track-card__content {
    position: relative;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
        flex-direction: column;
    -ms-flex: 1;
        flex: 1;
    font-size: 1rem;
    padding: 1rem; }
  .on-track-card__action {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: center;
        justify-content: center;
    -ms-flex-align: center;
        align-items: center;
    -ms-flex: 1;
        flex: 1;
    background: linear-gradient(to right, #56ab2f, #a8e063);
    /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    color: white;
    min-height: 260px;
    -webkit-clip-path: polygon(0 50px, 100% 0, 100% 100%, 0 100%);
            clip-path: polygon(0 50px, 100% 0, 100% 100%, 0 100%);
    padding-top: 2rem; }
    @media (min-width: 768px) {
      .on-track-card__action {
        -webkit-clip-path: polygon(50px 0, 100% 0, 100% 100%, 0 100%);
                clip-path: polygon(50px 0, 100% 0, 100% 100%, 0 100%);
        padding-top: auto; } }
    .on-track-card__action a {
      display: block;
      text-align: center;
      margin: 1rem 0;
      font-size: .9rem;
      font-weight: bold;
      text-decoration: none;
      color: white; }

.workout-container {
  position: relative; }

.workout-day {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  border: 1px solid #f6f6f6;
  margin-bottom: 1rem;
  border-radius: 4px;
  background-color: white;
  box-shadow: 0 7px 14px 0 rgba(60, 66, 87, 0.12), 0 3px 6px 0 rgba(167, 167, 167, 0.12); }
  .workout-day__row, .workout-day__header {
    position: relative;
    display: -ms-flexbox;
    display: flex;
    padding: .75rem 1rem;
    -ms-flex-align: center;
        align-items: center; }
    .workout-day__row h3, .workout-day__header h3 {
      margin: 0; }
    .workout-day__row:not(:last-of-type), .workout-day__header:not(:last-of-type) {
      border-bottom: 1px solid #f6f6f6; }
    .workout-day__row--set, .workout-day__header--set {
      display: -ms-flexbox;
      display: flex;
      font-size: .85rem;
      transition: all .3s ease-in-out; }
      .workout-day__row--set:hover, .workout-day__header--set:hover {
        background-color: #f6f6f6; }
    .workout-day__row.is-superset:before, .workout-day__header.is-superset:before {
      content: '';
      position: absolute;
      left: 0;
      bottom: 0;
      width: 2px;
      height: 200%;
      background-color: #4260f4; }
  .workout-day__note {
    margin: .5rem;
    border-radius: 4px;
    background-color: #dddddd;
    font-size: .85rem;
    line-height: 1.5; }
    .workout-day__note:before {
      content: '';
      position: absolute;
      top: -8px;
      left: 1rem;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 0 4px 8px 4px;
      border-color: transparent transparent #dddddd transparent; }
    .workout-day__note svg {
      width: 18px;
      height: 18px;
      fill: #2c2c2c;
      margin-right: .75rem; }
  .workout-day__header {
    padding: 1.25rem; }
  .workout-day__main {
    position: relative;
    max-height: 200px;
    overflow: hidden;
    transition: all .3s ease-in-out; }
    .workout-day__main:after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 120px;
      background: linear-gradient(to top, #f3f3f3 0%, rgba(255, 255, 255, 0) 100%); }
    .workout-day__main.is-expanded {
      max-height: 10000px; }
      .workout-day__main.is-expanded:after {
        content: none; }
  .workout-day__expand {
    position: absolute;
    bottom: 1rem;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: center;
        justify-content: center;
    width: 100%;
    z-index: 1; }
    .is-expanded .workout-day__expand .icon-btn--white {
      background-color: #4260f4; }
      .is-expanded .workout-day__expand .icon-btn--white svg {
        fill: white; }
  .workout-day__dayname {
    font-weight: bold;
    text-transform: uppercase;
    font-size: .9rem;
    letter-spacing: 1px;
    margin-right: .5rem; }
  .workout-day__workoutname {
    font-weight: bold;
    font-size: .9rem;
    letter-spacing: 1px;
    color: rgba(44, 44, 44, 0.9); }

.activity-calendar {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  grid-column: auto / span 4;
  background: linear-gradient(to right, #4260f4, #1cb5e0);
  padding: 1rem; }
  .activity-calendar__icon {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
        align-items: center;
    -ms-flex: none;
        flex: none;
    justify-self: flex-start; }
  .activity-calendar__title {
    color: rgba(255, 255, 255, 0.65);
    font-weight: bold;
    font-size: .9rem;
    letter-spacing: 1px;
    text-transform: uppercase; }
  .activity-calendar__datepicker {
    margin-left: auto; }
    .activity-calendar__datepicker button {
      height: 40px;
      border: none;
      border-radius: 4px;
      background-color: rgba(255, 255, 255, 0.15);
      color: white;
      font-weight: bold;
      padding: 0 1rem; }
  .activity-calendar__main {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
        flex-direction: column;
    -ms-flex: 1;
        flex: 1; }

.calendar {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  -ms-flex: 1;
      flex: 1;
  margin-top: 1rem; }
  .calendar__days {
    position: relative;
    display: grid;
    -ms-flex: none;
        flex: none;
    grid-template-columns: repeat(7, 1fr);
    border-bottom: 1px solid rgba(255, 255, 255, 0.15);
    padding-bottom: .5rem; }
  .calendar__day {
    text-align: center;
    font-weight: 600;
    color: rgba(255, 255, 255, 0.75);
    font-size: .9rem; }
  .calendar__grid {
    position: relative;
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    grid-auto-rows: 35px;
    -ms-flex: 1;
        flex: 1;
    padding-top: .5rem; }
  .calendar__grid-item {
    position: relative;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: center;
        justify-content: center;
    -ms-flex-align: center;
        align-items: center; }
    .calendar__grid-item span {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      font-size: .6rem;
      font-weight: 600;
      color: white; }
  .calendar__activity {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: center;
        justify-content: center;
    -ms-flex-align: center;
        align-items: center;
    width: 16px;
    height: 16px;
    background-color: white;
    background: radial-gradient(circle, white 0%, rgba(255, 255, 255, 0) 100%);
    border-radius: 50%;
    box-shadow: 0px 0px 15px -4px white; }
    .calendar__activity--med {
      width: 21px;
      height: 21px; }
    .calendar__activity--lrg {
      width: 26px;
      height: 26px; }
    .calendar__activity svg {
      width: 14px;
      height: 14px;
      fill: red; }

.notification {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  width: 100%;
  padding: .75rem;
  border-radius: 4px;
  background-color: white;
  margin-bottom: 1rem;
  box-shadow: 7px 7px 11px 0px rgba(0, 0, 0, 0.05); }
  .notification__icon {
    position: relative;
    margin-right: 1rem; }
    .notification__icon svg {
      width: 22px;
      height: 22px;
      fill: #2c2c2c; }
  .notification__content {
    -ms-flex: 1;
        flex: 1;
    margin-right: 50px; }
  .notification__close {
    -ms-flex: none;
        flex: none; }

.log-header {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  background-color: white;
  border-radius: 4px;
  margin-bottom: 1rem;
  overflow: hidden;
  box-shadow: 7px 7px 11px 0px rgba(0, 0, 0, 0.05); }
  .log-header__name {
    letter-spacing: 1px;
    font-weight: bold;
    padding: 1rem; }
  .log-header__timer {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
        align-items: center;
    -ms-flex: none;
        flex: none;
    padding-right: 1rem; }
    .log-header__timer svg {
      width: 20px;
      height: 20px;
      fill: #aaaaaa;
      margin-right: .5rem; }
  .log-header__progress {
    padding: 1rem;
    background-color: #4260f4;
    color: white;
    font-weight: bold; }

.log-body {
  position: relative; }

.log-exercise-details {
  position: relative; }
  @media (min-width: 600px) {
    .log-exercise-details {
      display: -ms-flexbox;
      display: flex; } }
  .log-exercise-details__image {
    position: relative;
    width: 100%; }
    .log-exercise-details__image img {
      width: 100%;
      height: auto; }
    .log-exercise-details__image svg {
      position: absolute;
      top: calc(50% - 20px);
      left: calc(50% - 20px);
      width: 40px;
      height: 40px;
      fill: rgba(255, 255, 255, 0.85); }
    @media (min-width: 600px) {
      .log-exercise-details__image {
        margin-right: 1rem;
        max-width: 260px; } }
  .log-exercise-details__content {
    -ms-flex: 1;
        flex: 1; }
  .log-exercise-details__title {
    font-weight: bold;
    font-size: 1.2rem; }
  .log-exercise-details__desc {
    display: none; }
    @media (min-width: 600px) {
      .log-exercise-details__desc {
        display: block; } }

.log-set {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  background-color: white;
  border-radius: 4px;
  margin-bottom: 1rem;
  transition: all .3s ease-in-out;
  box-shadow: 7px 7px 11px 0px rgba(0, 0, 0, 0.05); }
  @media (min-width: 992px) {
    .log-set {
      -ms-flex-direction: row;
          flex-direction: row;
      -ms-flex-align: center;
          align-items: center; } }
  .log-set--complete {
    background-color: #f4fdf4; }
  .log-set__number {
    font-weight: bold;
    border-bottom: 1px solid #f6f6f6;
    padding: 1rem; }
    @media (min-width: 992px) {
      .log-set__number {
        border-bottom: none; } }
  .log-set__target {
    padding: 1rem;
    border-bottom: 1px solid #f6f6f6; }
    .log-set__target small {
      font-size: .75rem;
      font-weight: bold;
      text-transform: uppercase; }
    .log-set__target p {
      font-size: 1.75rem; }
      .log-set__target p span {
        font-size: 1rem; }
    @media (min-width: 992px) {
      .log-set__target {
        -ms-flex: 1;
            flex: 1;
        border-bottom: none;
        border-right: 1px solid #f6f6f6;
        border-left: 1px solid #f6f6f6; } }
  .log-set__achieved {
    padding: 1rem; }
    .log-set__achieved small {
      font-size: .75rem;
      font-weight: bold;
      text-transform: uppercase; }
    @media (min-width: 992px) {
      .log-set__achieved {
        -ms-flex: 1;
            flex: 1; } }

.log-input {
  margin-top: .5rem; }
  .log-input input {
    display: inline-block;
    width: 100px;
    margin-right: .75rem; }
    .log-input input::-moz-placeholder {
      color: #dddddd; }
    .log-input input:-ms-input-placeholder {
      color: #dddddd; }
    .log-input input::placeholder {
      color: #dddddd; }
  .log-input p {
    display: inline-block; }

.log-complete {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-pack: center;
      justify-content: center; }
  .log-complete__inner {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
        flex-direction: column;
    -ms-flex-align: center;
        align-items: center; }
    .log-complete__inner img {
      width: 100%;
      max-width: 460px;
      height: auto;
      margin-bottom: 1rem; }

.log-book-item {
  position: relative;
  background-color: white;
  border-radius: 4px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  margin-bottom: 1rem;
  overflow: hidden;
  box-shadow: 0 7px 14px 0 rgba(60, 66, 87, 0.12), 0 3px 6px 0 rgba(167, 167, 167, 0.12);
  transition: all .3s ease-in-out; }
  @media (min-width: 768px) {
    .log-book-item {
      -ms-flex-direction: row;
          flex-direction: row; }
      .log-book-item:hover {
        transform: translateY(-2px);
        box-shadow: 0 9px 14px 0 rgba(60, 66, 87, 0.12), 0 3px 6px 0 rgba(167, 167, 167, 0.12); } }
  .log-book-item__date {
    position: relative;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
        align-items: center;
    border-bottom: 1px solid #f6f6f6;
    padding: 1rem;
    background-color: rgba(66, 96, 244, 0.75);
    color: white;
    font-size: .85rem; }
    .log-book-item__date svg {
      width: 18px;
      height: 18px;
      fill: white;
      margin-right: .75rem; }
    @media (min-width: 768px) {
      .log-book-item__date {
        border-bottom: none;
        border-right: 1px solid #f6f6f6;
        width: 180px; } }
  .log-book-item__name {
    position: relative;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
        align-items: center;
    border-bottom: 1px solid #f6f6f6;
    padding: 1rem;
    -ms-flex: 1;
        flex: 1; }
    @media (min-width: 768px) {
      .log-book-item__name {
        border-bottom: none; } }
  .log-book-item__stats {
    margin-left: auto;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
        align-items: center; }
    .log-book-item__stats span {
      display: -ms-flexbox;
      display: flex;
      -ms-flex-align: center;
          align-items: center;
      font-size: .85rem;
      font-weight: bold;
      margin-left: .75rem; }
      .log-book-item__stats span svg {
        width: 18px;
        height: 18px;
        fill: #4260f4;
        margin-right: .5rem; }
      .log-book-item__stats span.yellow svg {
        fill: #f2c94c; }
  .log-book-item__action {
    position: relative;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
        align-items: center;
    padding: .5rem; }
    .log-book-item__action .icon-btn {
      margin-left: auto; }
    @media (min-width: 768px) {
      .log-book-item__action {
        margin-left: auto; } }

.pagination {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  margin-bottom: 1rem; }
  .pagination__prev {
    -ms-flex: none;
        flex: none;
    padding: .5rem; }
  .pagination__next {
    margin-left: auto;
    padding: .5rem; }

.overlay {
  position: fixed;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: center;
      align-items: center;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(44, 44, 44, 0.75);
  visibility: hidden;
  opacity: 0;
  z-index: 101;
  transition: all .3s ease-in-out; }
  .overlay.is-open {
    visibility: visible;
    opacity: 1; }
  .overlay--nav {
    z-index: 99; }

.exercise-modal-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-pack: center;
      justify-content: center;
  padding: 1rem;
  z-index: 102; }
  @media (min-width: 992px) {
    .exercise-modal-container {
      padding: 2rem; } }

.exercise-modal {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  width: 100%;
  height: 100%;
  background-color: white;
  overflow: auto;
  border-radius: 4px;
  box-shadow: 7px 7px 11px 0px rgba(0, 0, 0, 0.05); }
  @media (min-width: 992px) {
    .exercise-modal {
      -ms-flex-direction: row;
          flex-direction: row;
      overflow: hidden; } }
  .exercise-modal__media {
    position: relative;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
        align-items: center;
    -ms-flex: none;
        flex: none;
    width: 100%;
    height: 240px;
    background-color: #777777; }
    .exercise-modal__media iframe {
      width: 100%;
      height: 100%;
      height: -webkit-fill-available; }
    @media (min-width: 992px) {
      .exercise-modal__media {
        -ms-flex: 1 0 50%;
            flex: 1 0 50%;
        height: 100%; } }
  .exercise-modal__content {
    -ms-flex: 1;
        flex: 1;
    padding: 1rem; }
    @media (min-width: 992px) {
      .exercise-modal__content {
        overflow: auto;
        -ms-flex: 1 0 50%;
            flex: 1 0 50%; } }
  .exercise-modal__close {
    position: absolute;
    top: .5rem;
    right: .5rem; }

.general-modal {
  position: relative;
  width: 760px;
  height: 600px;
  background-color: white;
  border-radius: 4px;
  padding: 1rem;
  opacity: 0;
  visibility: hidden;
  transition: all .3s ease-in-out; }
  .general-modal.is-open {
    visibility: visible;
    opacity: 1; }

.video-library-grid {
  position: relative;
  display: grid;
  grid-column-gap: 1rem;
  grid-row-gap: 2rem;
  grid-template-columns: repeat(2, 1fr);
  margin-bottom: 2rem; }
  @media (min-width: 768px) {
    .video-library-grid {
      grid-template-columns: repeat(3, 1fr); } }

.video-item {
  position: relative; }
  .video-item__image {
    position: relative;
    width: 100%;
    background-color: #2c2c2c; }
    .video-item__image svg {
      position: absolute;
      top: calc(50% - 20px);
      left: calc(50% - 20px);
      width: 40px;
      height: 40px;
      fill: rgba(255, 255, 255, 0.85); }
    .video-item__image img {
      width: 100%;
      opacity: 1;
      transition: all .3s ease-in-out; }
    .video-item__image:hover img {
      opacity: .65; }
    .video-item__image--none {
      display: -ms-flexbox;
      display: flex;
      background-color: #dddddd;
      -ms-flex-align: center;
          align-items: center;
      -ms-flex-pack: center;
          justify-content: center;
      font-weight: bold; }
  .video-item__title {
    padding-top: .5rem;
    font-weight: bold; }

.video-not-found {
  grid-column: auto / span 100; }

.drawer {
  position: fixed;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  top: 0;
  right: -100%;
  width: 100%;
  max-width: 400px;
  height: 100vh;
  background-color: white;
  overflow-y: auto;
  z-index: 500;
  transition: right .3s ease-in-out; }
  .drawer__header {
    -ms-flex: none;
        flex: none;
    padding: 1rem; }
  .drawer__content {
    -ms-flex: 1;
        flex: 1;
    overflow-y: auto; }
    .drawer__content label {
      color: #2c2c2c; }
    .drawer__content input {
      background-color: rgba(195, 195, 195, 0.4); }
  .drawer__footer {
    -ms-flex: none;
        flex: none; }
  .drawer.is-open {
    right: 0; }

.chart-card {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  grid-column: auto / span 5;
  background: linear-gradient(to right, #4260f4, #1cb5e0); }
  .chart-card__icon {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
        align-items: center;
    -ms-flex: none;
        flex: none;
    justify-self: flex-start;
    padding: 1rem; }
  .chart-card__title {
    color: rgba(255, 255, 255, 0.65);
    font-weight: bold;
    font-size: .9rem;
    letter-spacing: 1px;
    text-transform: uppercase; }
  .chart-card__selector {
    margin-left: auto; }
    .chart-card__selector select {
      height: 40px;
      border: none;
      border-radius: 4px;
      background-color: rgba(255, 255, 255, 0.15);
      color: white;
      font-weight: bold;
      padding: 0 1rem; }
      .chart-card__selector select option {
        color: #2c2c2c; }
  .chart-card__main {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
        flex-direction: column;
    -ms-flex: 1;
        flex: 1;
    overflow: hidden; }
    .chart-card__main .recharts-responsive-container {
      position: relative;
      bottom: -3.1rem; }

.icon-dropdown {
  position: relative; }
  .profile-card .icon-dropdown {
    position: absolute;
    top: 1rem;
    right: 1rem; }
  .icon-dropdown__trigger {
    position: relative;
    background-color: transparent;
    border: none;
    padding: 0; }
    .icon-dropdown__trigger svg {
      width: 26px;
      height: 26px;
      fill: #2c2c2c; }
      .icon-dropdown.small .icon-dropdown__trigger svg {
        width: 18px;
        height: 18px; }
  .icon-dropdown__menu {
    position: absolute;
    padding: .75rem 0;
    border-radius: .5rem;
    background-color: white;
    box-shadow: 3px 3px 5px 0px rgba(0, 0, 0, 0.025);
    visibility: hidden;
    z-index: 1; }
    .icon-dropdown__trigger[aria-expanded=true] + .icon-dropdown__menu {
      visibility: visible; }
    .icon-dropdown__menu a,
    .icon-dropdown__menu button {
      position: relative;
      display: block;
      text-decoration: none;
      color: #2c2c2c;
      padding: .5rem .75rem;
      border: none;
      background-color: transparent; }
      .icon-dropdown__menu a:hover,
      .icon-dropdown__menu button:hover {
        background-color: #aaaaaa; }
    .icon-dropdown.right .icon-dropdown__menu {
      right: 0; }
    .icon-dropdown.center .icon-dropdown__menu {
      right: 50%;
      transform: translateX(-50%); }

.filter-dropdown {
  position: relative;
  width: 40px; }
  .filter-dropdown__trigger {
    display: -ms-flexbox;
    display: flex;
    width: 40px;
    height: 40px;
    background-color: #aaaaaa;
    -ms-flex-align: center;
        align-items: center;
    -ms-flex-pack: center;
        justify-content: center;
    padding: 0;
    border-radius: 4px;
    border: none; }
    .filter-dropdown__trigger svg {
      width: 24px;
      height: 24px;
      fill: white; }
  .filter-dropdown__content {
    display: none;
    position: absolute;
    top: calc(100% + .5rem);
    right: 0;
    width: 220px;
    padding: .75rem;
    background-color: white;
    border-radius: 4px;
    z-index: 1;
    box-shadow: 0 7px 14px 0 rgba(60, 66, 87, 0.12), 0 3px 6px 0 rgba(167, 167, 167, 0.12); }
    .filter-dropdown__trigger[aria-expanded=true] + .filter-dropdown__content {
      display: block; }
  .filter-dropdown__title {
    font-size: .85rem;
    text-transform: uppercase;
    font-weight: bold;
    padding-bottom: .5rem;
    border-bottom: 1px solid #dddddd; }
  .filter-dropdown__item {
    padding: .5rem 0; }
    .filter-dropdown__item label {
      font-size: .85rem;
      color: #2c2c2c;
      margin: 0 0 .25rem 0; }
    .filter-dropdown__item select {
      margin: 0; }

.workout-editor-setup {
  position: relative;
  width: 100%;
  background-color: white;
  border-radius: 12px;
  padding: 1rem;
  margin-bottom: 1rem;
  box-shadow: 0 7px 14px 0 rgba(60, 66, 87, 0.12), 0 3px 6px 0 rgba(167, 167, 167, 0.12); }
  @media (min-width: 768px) {
    .workout-editor-setup {
      border-radius: 4px; } }
  .workout-editor-setup__inner {
    position: relative;
    width: 100%;
    display: grid; }
    .workout-editor-setup__inner label {
      color: #2c2c2c; }
    .workout-editor-setup__inner input {
      background-color: rgba(195, 195, 195, 0.4); }
    @media (min-width: 768px) {
      .workout-editor-setup__inner {
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 1rem; } }

.workout-editor-toolbar {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  background-color: white;
  border-radius: 12px;
  padding: 1rem;
  margin-bottom: 1rem;
  box-shadow: 0 7px 14px 0 rgba(60, 66, 87, 0.12), 0 3px 6px 0 rgba(167, 167, 167, 0.12); }
  .workout-editor-toolbar .btn:not(:last-of-type) {
    margin-right: 1rem; }
  @media (min-width: 768px) {
    .workout-editor-toolbar {
      border-radius: 4px; } }

.workout-editor-day {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  width: 100%;
  background-color: white;
  border-radius: 12px;
  padding: 1rem;
  margin-bottom: 1rem;
  box-shadow: 0 7px 14px 0 rgba(60, 66, 87, 0.12), 0 3px 6px 0 rgba(167, 167, 167, 0.12); }
  @media (min-width: 768px) {
    .workout-editor-day {
      border-radius: 4px; } }
  .workout-editor-day__header {
    position: relative;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    -ms-flex-align: center;
        align-items: center;
    border-bottom: 1px solid #f6f6f6;
    margin-bottom: .75rem; }
    .workout-editor-day__header input[type=text] {
      border: none;
      padding-left: 0; }
  .workout-editor-day__day-picker {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
        align-items: center; }
    .workout-editor-day__day-picker select {
      margin-left: .75rem; }
  .workout-editor-day__main {
    position: relative;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
        flex-direction: column;
    width: 100%; }

.workout-editor-exercise {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  background-color: #f6f6f6;
  padding: 0 .75rem;
  margin-bottom: .75rem;
  border: 1px solid #dddddd;
  border-radius: 12px; }
  @media (min-width: 768px) {
    .workout-editor-exercise {
      border-radius: 4px; } }
  .workout-editor-exercise__tag {
    position: absolute;
    font-size: .9rem;
    color: white;
    background-color: #4260f4;
    padding: 2px;
    border-radius: 2px;
    top: -9px; }
  .workout-editor-exercise__header {
    position: relative;
    display: -ms-flexbox;
    display: flex;
    padding: .75rem 0;
    -ms-flex-align: center;
        align-items: center; }
    .workout-editor-exercise__header .sidebar-item {
      margin: 0; }
  .workout-editor-exercise__main {
    position: relative;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
        flex-direction: column; }

.workout-editor-set {
  position: relative;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  padding: .75rem 0;
  font-size: 0.9rem;
  border-top: 1px solid #dddddd; }
  .workout-editor-set__title {
    font-weight: bold;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
        align-items: center; }
  .workout-editor-set__reps {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
        align-items: center; }
    .workout-editor-set__reps input {
      margin-left: .75rem; }
  .workout-editor-set__weight {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
        align-items: center; }
    .workout-editor-set__weight input {
      margin-left: .75rem; }
  .workout-editor-set__actions {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
        align-items: center;
    -ms-flex-pack: end;
        justify-content: flex-end; }

.exercise-selector-container {
  position: fixed;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: center;
      align-items: center;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 102; }

.exercise-selector {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  width: calc(100% - 2rem);
  max-width: 1200px;
  height: calc(100vh - 100px);
  background-color: white;
  border-radius: 12px;
  z-index: 1;
  overflow: hidden;
  box-shadow: 0 7px 14px 0 rgba(60, 66, 87, 0.12), 0 3px 6px 0 rgba(167, 167, 167, 0.12);
  opacity: 0;
  visibility: hidden;
  transition: all .3s ease-in-out; }
  .exercise-selector.is-open {
    opacity: 1;
    visibility: visible; }
  @media (min-width: 768px) {
    .exercise-selector {
      border-radius: 4px; } }
  .exercise-selector__main {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
        flex-direction: column;
    -ms-flex: 1;
        flex: 1;
    border-right: 1px solid #f6f6f6; }
  .exercise-selector__sidebar {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
        flex-direction: column;
    -ms-flex: none;
        flex: none;
    width: 300px; }
  .exercise-selector__header {
    padding: 1rem; }
    .exercise-selector__header h2 {
      margin-bottom: 0; }
  .exercise-selector__footer {
    -ms-flex: none;
        flex: none; }
    .exercise-selector__footer .btn {
      width: 100%;
      border-radius: 0; }
  .exercise-selector__list {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
        flex-direction: column;
    -ms-flex: 1;
        flex: 1;
    background-color: #f6f6f6;
    overflow-y: auto; }
    .exercise-selector__list--basket {
      background-color: white;
      border-top: 1px solid #f6f6f6; }
  .exercise-selector__item {
    position: relative;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
        align-items: center;
    padding: 1rem; }
    .exercise-selector__item:after {
      content: '';
      position: relative;
      margin-left: auto;
      width: 14px;
      height: 14px;
      border-radius: 50%;
      background-color: #c3c3c3;
      border: 12px solid #c3c3c3; }
    .exercise-selector__item:not(:last-of-type) {
      border-bottom: 1px solid #dddddd; }
    .exercise-selector__item.is-selected {
      background-color: #dddddd; }
      .exercise-selector__item.is-selected:after {
        background-color: white; }
  .exercise-selector__basket-item {
    position: relative;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
        align-items: center;
    padding: 1rem; }

.progress-upload {
  position: relative;
  grid-column: 1 / span all;
  background-color: white;
  border-radius: 12px;
  margin-bottom: 1rem;
  padding: 1rem;
  box-shadow: 0 7px 14px 0 rgba(60, 66, 87, 0.12), 0 3px 6px 0 rgba(167, 167, 167, 0.12); }
  @media (min-width: 768px) {
    .progress-upload {
      border-radius: 4px; } }
  .progress-upload__area {
    position: relative;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
        align-items: center;
    -ms-flex-pack: center;
        justify-content: center;
    width: 100%;
    border: 2px dashed rgba(170, 170, 170, 0.35);
    border-radius: 12px;
    min-height: 240px;
    cursor: pointer;
    transition: all .3s ease-in-out; }
    .progress-upload__area input[type=file] {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: 0;
      cursor: pointer; }
    .progress-upload__area:hover {
      border-color: rgba(66, 96, 244, 0.35);
      background-color: rgba(66, 96, 244, 0.15); }
    @media (min-width: 768px) {
      .progress-upload__area {
        border-radius: 4px; } }
  .progress-upload__inner {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
        flex-direction: column;
    -ms-flex-align: center;
        align-items: center;
    padding: 1rem; }
    .progress-upload__inner svg {
      position: relative;
      max-width: 90px;
      pointer-events: none; }
    .progress-upload__inner h3 {
      text-align: center; }
      .progress-upload__inner h3 span {
        color: #4260f4; }

.progress-gallery {
  grid-column: 1 / span all; }
  .progress-gallery__title {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    -ms-flex-align: center;
        align-items: center;
    margin-bottom: 1rem;
    padding-bottom: 1rem;
    border-bottom: 1px solid #dddddd; }
    .progress-gallery__title h3 {
      margin: 0; }
    .progress-gallery .progress-gallery__title button {
      background-color: #4260f4;
      border-radius: 4px;
      height: 40px;
      color: white;
      font-weight: bold;
      padding: 0 1rem;
      border: none; }

.progress-gallery-section {
  position: relative;
  margin-bottom: 2rem; }
  .progress-gallery-section__title {
    position: relative;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
        align-items: center;
    margin-bottom: 1rem;
    font-size: .85rem;
    font-weight: bold; }
    .progress-gallery-section__title svg {
      width: 18px;
      height: 18px;
      fill: #4260f4;
      margin-right: .75rem; }
  .progress-gallery-section__list {
    position: relative;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 1rem; }
    @media (min-width: 768px) {
      .progress-gallery-section__list {
        grid-template-columns: repeat(8, 1fr); } }
  .progress-gallery-section hr {
    background: rgba(170, 170, 170, 0.35);
    margin-top: 2rem; }

.progress-gallery-thumbnail {
  position: relative;
  width: 100%;
  cursor: pointer;
  transition: all .3s ease-in-out; }
  .progress-gallery-thumbnail:hover {
    transform: scale(1.05); }
    .progress-gallery-thumbnail:hover img {
      box-shadow: 0 7px 14px 0 rgba(60, 66, 87, 0.12), 0 3px 6px 0 rgba(167, 167, 167, 0.12); }
  .progress-gallery-thumbnail.is-loading {
    height: 150px;
    background-color: #dddddd;
    border-radius: 4px; }
  .progress-gallery-thumbnail img {
    position: relative;
    width: 100%;
    height: auto;
    transition: all .3s ease-in-out; }

.react-datepicker {
  width: 100%; }
  .react-datepicker__month-container {
    width: 100%; }
  .react-datepicker__header.react-datepicker-year-header {
    height: 50px;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: center;
        justify-content: center;
    -ms-flex-align: center;
        align-items: center;
    color: #2c2c2c;
    padding: 0; }
  .react-datepicker__month-wrapper {
    display: -ms-flexbox;
    display: flex; }
  .react-datepicker__month-text {
    padding: 1rem;
    -ms-flex: 1;
        flex: 1; }
    .react-datepicker__month-text.react-datepicker__month--selected {
      background-color: #4260f4; }
  .react-datepicker button {
    background-color: transparent; }
    .react-datepicker button.react-datepicker__navigation--previous {
      left: 0;
      height: 10px;
      padding: 0; }
    .react-datepicker button.react-datepicker__navigation--next {
      right: 0;
      height: 10px;
      padding: 0; }
  .react-datepicker__portal > div {
    width: 100%;
    max-width: 300px; }

.gallery-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-pack: center;
      justify-content: center;
  background-color: rgba(44, 44, 44, 0.75);
  visibility: hidden;
  opacity: 0;
  z-index: 101;
  transition: all .3s ease-in-out; }
  .gallery-modal-overlay.is-open {
    visibility: visible;
    opacity: 1; }

.gallery-modal-content {
  position: relative; }
  .gallery-modal-content img {
    position: relative;
    height: 100%;
    max-height: calc(100vh - 2rem);
    max-width: calc(100vw - 2rem);
    -o-object-fit: contain;
       object-fit: contain;
    -o-object-position: center;
       object-position: center; }

.gallery-modal-controls {
  width: 100vw;
  height: 50px;
  position: absolute;
  top: calc(50vh - 25px);
  pointer-events: none; }
  .gallery-modal-controls button {
    position: absolute;
    width: 50px;
    height: 50px;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: center;
        justify-content: center;
    -ms-flex-align: center;
        align-items: center;
    background-color: black;
    border: none;
    pointer-events: all; }
    .gallery-modal-controls button svg {
      width: 24px;
      height: 24px;
      fill: white; }
    .gallery-modal-controls button[disabled] {
      opacity: .4; }
    .gallery-modal-controls button:last-of-type {
      right: 0; }
    @media (min-width: 768px) {
      .gallery-modal-controls button {
        width: 60px;
        height: 60px; } }

.latest-updates-container {
  position: relative;
  grid-column: auto / span 8;
  white-space: nowrap;
  overflow-x: auto;
  margin-bottom: 1rem;
  width: 100%; }
  .latest-updates-container:after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    width: 130px;
    height: 100%;
    background-color: whitesmoke;
    background: linear-gradient(to right, rgba(246, 246, 246, 0) 0%, #f6f6f6 100%);
    pointer-events: none; }

.item-card {
  position: relative;
  display: inline-block;
  width: 340px;
  height: 190px;
  border-radius: 12px;
  background-color: #aaaaaa;
  overflow: hidden;
  margin-right: 1rem; }
  .item-card__image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }
    .item-card__image img {
      width: 100%;
      height: 100%;
      -o-object-fit: cover;
         object-fit: cover;
      -o-object-position: center;
         object-position: center; }
    .item-card__image:after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(44, 44, 44, 0.3);
      mix-blend-mode: multiply;
      transition: all .3s ease-in-out; }
      .item-card:hover .item-card__image:after {
        background-color: rgba(44, 44, 44, 0.7); }
  .item-card__content {
    position: absolute;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
        flex-direction: column;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    color: white;
    padding: 1rem; }
  .item-card__title {
    margin-top: auto;
    font-family: "Open Sans", "Helvetica", "Arial", sans-serif;
    font-weight: bold; }

.complex-chart {
  position: relative;
  grid-column: auto / span 8;
  overflow: hidden; }
  @media (min-width: 768px) {
    .complex-chart {
      display: -ms-flexbox;
      display: flex;
      min-height: 435px; } }
  .complex-chart__nav {
    position: relative;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: nowrap;
        flex-wrap: nowrap;
    overflow-x: auto;
    padding: .75rem;
    border-bottom: 1px solid #dddddd; }
    @media (min-width: 768px) {
      .complex-chart__nav {
        -ms-flex: none;
            flex: none;
        -ms-flex-direction: column;
            flex-direction: column;
        width: 180px;
        padding: 0;
        border-right: 1px solid #dddddd; }
        .complex-chart__nav .pill-btn {
          background-color: transparent;
          color: #2c2c2c;
          width: 100%;
          border-bottom: 1px solid #dddddd;
          height: 50px;
          margin: 0;
          border-radius: 0; }
          .complex-chart__nav .pill-btn.is-active {
            background-color: #4260f4;
            color: white; } }
  .complex-chart__main {
    position: relative;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
        flex-direction: column;
    -ms-flex-align: center;
        align-items: center;
    -ms-flex-pack: center;
        justify-content: center; }
    .complex-chart__main .recharts-responsive-container {
      margin: 1rem 0; }
    @media (min-width: 768px) {
      .complex-chart__main {
        -ms-flex: 1;
            flex: 1;
        height: 100%; } }
  .complex-chart__selection {
    position: relative;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    padding: .75rem;
    -ms-flex-align: center;
        align-items: center;
    border-bottom: 1px solid #dddddd; }
    .complex-chart__selection select {
      height: 38px;
      margin-right: .5rem;
      font-size: .85rem;
      border: 1px solid #c3c3c3;
      width: 100%;
      max-width: 130px; }
  .complex-chart__datepicker {
    display: -ms-flexbox;
    display: flex;
    margin-left: auto;
    -ms-flex-align: center;
        align-items: center; }
    .complex-chart__datepicker span {
      font-weight: bold;
      margin: 0 .5rem;
      font-size: .85rem; }
    .complex-chart__datepicker .btn {
      height: 38px;
      -ms-flex: 1 0 50%;
          flex: 1 0 50%;
      margin: 0; }

.adjustment-row {
  position: relative;
  display: grid;
  grid-gap: .5rem;
  grid-template-columns: repeat(2, 1fr);
  width: 100%;
  border-top: 1px solid #dddddd;
  padding: .25rem 0 1rem; }
  @media (min-width: 768px) {
    .adjustment-row {
      display: -ms-flexbox;
      display: flex;
      padding: 1rem 0; } }
  .adjustment-row__date {
    grid-column: 1 / span 2;
    font-size: .9rem;
    font-weight: bold;
    padding: .5rem 0 .5rem; }
    @media (min-width: 768px) {
      .adjustment-row__date {
        font-size: 1rem;
        -ms-flex: 1;
            flex: 1;
        padding: .75rem; } }
  .adjustment-row__value {
    -ms-flex: 1;
        flex: 1;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
        flex-direction: column;
    -ms-flex-pack: center;
        justify-content: center; }
    .adjustment-row__value span {
      display: block; }
      .adjustment-row__value span:first-of-type {
        font-size: .75rem;
        opacity: .85; }
      .adjustment-row__value span:last-of-type {
        font-weight: bold;
        font-size: .85rem;
        margin-top: 4px; }

.main-header {
  position: fixed;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  top: 0;
  left: 0;
  width: 100%;
  height: 80px;
  padding: 0 1rem;
  background-color: white;
  top: -80px;
  z-index: 101;
  box-shadow: 0 7px 14px 0 rgba(60, 66, 87, 0.12), 0 3px 6px 0 rgba(167, 167, 167, 0.12);
  transition: all .3s ease-in-out; }
  .main-header__rc {
    position: absolute;
    left: 0;
    bottom: -20px;
    width: 20px;
    height: 20px; }
    .main-header__rc svg {
      width: 20px;
      height: 20px;
      fill: white; }
    .main-header__rc--rhs {
      left: auto;
      right: 0; }
      .main-header__rc--rhs svg {
        transform: scaleX(-1); }
    @media (min-width: 992px) {
      .main-header__rc {
        display: none; } }
  .main-header__mobile-actions {
    margin-left: auto; }
    @media (min-width: 992px) {
      .main-header__mobile-actions {
        display: none; } }
  .main-header__rhs {
    display: none; }
    @media (min-width: 992px) {
      .main-header__rhs {
        display: -ms-inline-flexbox;
        display: inline-flex;
        -ms-flex-align: center;
            align-items: center;
        margin-left: auto; }
        .main-header__rhs .icon-dropdown {
          margin-right: 1rem; } }
  .main-header.is-visible {
    top: 0; }

.sub-nav {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: nowrap;
      flex-wrap: nowrap;
  margin-top: 1rem;
  margin-bottom: 2rem;
  width: 100vw;
  margin-left: -1rem;
  padding-left: 1rem;
  overflow: auto; }
  @media (min-width: 768px) {
    .sub-nav {
      width: 100%;
      max-width: 100%;
      margin-left: 0;
      padding-left: 0;
      padding-bottom: 1rem;
      margin-bottom: 1rem;
      border-bottom: 1px solid #dddddd; } }

.header-logo {
  position: relative; }
  .header-logo img {
    position: relative;
    width: auto;
    height: 26px; }
  .header-logo.beta:after {
    content: 'beta';
    font-size: .5rem;
    font-weight: bold;
    color: white;
    background-color: #4260f4;
    padding: 1px 4px;
    border-radius: 4px;
    position: absolute;
    right: 0;
    top: -8px;
    text-transform: uppercase;
    letter-spacing: 1px; }

.avatar {
  position: relative;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #dddddd;
  overflow: hidden; }
  .avatar input[type=file] {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0; }
  .avatar__initials {
    position: relative;
    display: -ms-flexbox;
    display: flex;
    text-transform: uppercase;
    color: white;
    width: 100%;
    height: 100%;
    -ms-flex-pack: center;
        justify-content: center;
    -ms-flex-align: center;
        align-items: center; }
    .avatar--large .avatar__initials {
      font-size: 2.75rem; }
  .avatar__image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
       object-fit: cover;
    -o-object-position: center;
       object-position: center; }
  .avatar__overlay {
    position: absolute;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: center;
        justify-content: center;
    -ms-flex-align: center;
        align-items: center;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(44, 44, 44, 0.5);
    opacity: 0;
    visibility: hidden;
    transition: all .3s ease-in-out; }
    .avatar__overlay svg {
      width: 30px;
      height: 30px;
      fill: white; }
    .avatar:hover .avatar__overlay {
      opacity: 1;
      visibility: visible; }
  .avatar--large {
    width: 120px;
    height: 120px; }

.sidebar {
  position: fixed;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  top: 80px;
  right: -340px;
  width: 100%;
  max-width: 340px;
  height: calc(100vh - 80px);
  background-color: #e6e6e6;
  transition: all .3s ease-in-out;
  z-index: 100;
  box-shadow: 7px 7px 11px 0px rgba(0, 0, 0, 0.05); }
  @media (min-width: 992px) {
    .sidebar {
      display: block;
      right: auto;
      left: -200px;
      width: 200px;
      box-shadow: none; } }
  .sidebar.mobile-open {
    right: 0; }
    @media (min-width: 992px) {
      .sidebar.mobile-open {
        right: auto; } }
  @media (min-width: 992px) {
    .sidebar.is-visible {
      right: auto;
      left: 0; } }

.sidebar-item {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  -ms-flex-align: center;
      align-items: center;
  text-decoration: none;
  padding: 0 1rem;
  margin: 1rem 0;
  background-color: transparent;
  border: none; }
  .sidebar-item__icon {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: center;
        justify-content: center;
    -ms-flex-align: center;
        align-items: center;
    width: 40px;
    height: 40px;
    background-color: #f6f6f6;
    border-radius: 4px;
    margin-right: .5rem;
    transition: all .3s ease-in-out; }
    .sidebar-item__icon svg {
      width: 22px;
      height: 22px;
      fill: #2c2c2c;
      transition: all .3s ease-in-out; }
    .is-active .sidebar-item__icon {
      background-color: #4260f4; }
      .is-active .sidebar-item__icon svg {
        fill: white; }
  .sidebar-item__text {
    color: #2c2c2c;
    font-size: .85rem;
    text-transform: uppercase;
    letter-spacing: 1px; }
  .sidebar-item--alt {
    -ms-flex-direction: column;
        flex-direction: column;
    text-align: center; }
    .sidebar-item--alt:hover .sidebar-item__icon {
      background-color: #f6f6f6; }
    .sidebar-item--alt .sidebar-item__icon {
      margin-right: 0;
      margin-bottom: .5rem;
      background-color: white; }
    .sidebar-item--alt .sidebar-item__text {
      font-size: .75rem;
      font-weight: bold; }
  .sidebar-item--logout {
    margin-top: auto; }
    .sidebar-item--logout .sidebar-item__icon {
      background-color: #da1727; }
      .sidebar-item--logout .sidebar-item__icon svg {
        fill: white; }
    @media (min-width: 992px) {
      .sidebar-item--logout {
        display: none; } }
  .activity-calendar .sidebar-item__icon, .chart-card .sidebar-item__icon {
    background-color: rgba(255, 255, 255, 0.15); }
    .activity-calendar .sidebar-item__icon svg, .chart-card .sidebar-item__icon svg {
      fill: white; }

.table {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column; }

.table-row {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  padding: 1rem;
  border-top: 1px solid #dddddd; }

.hidden-mobile {
  display: none; }
  @media (min-width: 992px) {
    .hidden-mobile {
      display: block; }
      .hidden-mobile.split-panel__section {
        display: -ms-flexbox;
        display: flex; } }

.visually-hidden {
  position: absolute !important;
  height: 1px;
  width: 1px;
  overflow: hidden;
  clip: rect(1px 1px 1px 1px);
  /* IE6, IE7 */
  clip: rect(1px, 1px, 1px, 1px);
  white-space: nowrap;
  /* added line */ }

.mt-1 {
  margin-top: 1rem; }

.mt-2 {
  margin-top: 2rem; }

.mb-1 {
  margin-bottom: 1rem; }

.mb-2 {
  margin-bottom: 2rem; }

.mb-3 {
  margin-bottom: 3rem; }

.mr-1 {
  margin-right: 1rem; }

.ml-auto {
  margin-left: auto; }

.p-1 {
  padding: 1rem; }
